export const loadSignup = () => {
    window.cfields = [];

    const _load_script = (url, callback) => {
        let head = document.querySelector('head')
        let script = document.createElement('script')
        let r = false;
        script.type = 'text/javascript';
        script.charset = 'utf-8';
        script.src = url;
        if (callback) {
            script.onload = script.onreadystatechange = function() {
                if (!r && (!this.readyState || this.readyState === 'complete')) {
                    r = true;
                    callback();
                }
            };
        }
        head.appendChild(script);
    } 

    window._load_script = _load_script;

    window._show_thank_you = function(id, message, trackcmp_url, email) {
        let form = document.getElementById('_form_' + id + '_'), thank_you = form.querySelector('._form-thank-you');
        form.querySelector('._form-content').style.display = 'none';
        thank_you.innerHTML = message;
        thank_you.style.display = 'block';
        if (typeof(trackcmp_url) != 'undefined' && trackcmp_url) {
            // Site tracking URL to use after inline form submission.
            _load_script(trackcmp_url);
        }
        if (typeof window._form_callback !== 'undefined') {
            window._form_callback(id);
        }
    };
    window._show_error = function(id, message, html) {
        let form = document.getElementById('_form_' + id + '_')
        let err = document.createElement('div')
        let button = form.querySelector('button')
        let old_error = form.querySelector('._form_error');
        if (old_error) old_error.parentNode.removeChild(old_error);
        err.innerHTML = message;
        err.className = '_error-inner _form_error _no_arrow';
        let wrapper = document.createElement('div');
        wrapper.className = '_form-inner';
        wrapper.appendChild(err);
        button.parentNode.insertBefore(wrapper, button);
        document.querySelector('[id^="_form"][id$="_submit"]').disabled = false;
        if (html) {
            let div = document.createElement('div');
            div.className = '_error-html';
            div.innerHTML = html;
            err.appendChild(div);
        }
    };

(function() {
  if (window.location.search.search('excludeform') !== -1) return false;
  let addEvent = function(element, event, func) {
    if (element.addEventListener) {
      element.addEventListener(event, func);
    } else {
      let oldFunc = element['on' + event];
      element['on' + event] = function() {
        oldFunc.apply(this, arguments);
        func.apply(this, arguments);
      };
    }
  }
  let _removed = false;
  let form_to_submit = document.getElementById('_form_5_');
  let allInputs = form_to_submit.querySelectorAll('input, select, textarea'), tooltips = [], submitted = false;

  let getUrlParam = function(name) {
    let regexStr = '[\\?&]' + name + '=([^&#]*)';
    let results = new RegExp(regexStr, 'i').exec(window.location.href);
    return results !== null && results !== undefined ? decodeURIComponent(results[1]) : false;
  };

  for (let i = 0; i < allInputs.length; i++) {
    let regexStr = 'field\\[(\\d+)\\]';
    let results = new RegExp(regexStr).exec(allInputs[i].name);
    if (results !== undefined && results !== null) {
      allInputs[i].dataset.name = window.cfields[results[1]];
    } else {
      allInputs[i].dataset.name = allInputs[i].name;
    }
    let fieldVal = getUrlParam(allInputs[i].dataset.name);

    if (fieldVal) {
      if (allInputs[i].dataset.autofill === 'false') {
        continue;
      }
      if (allInputs[i].type === 'radio' || allInputs[i].type === 'checkbox') {
        if (allInputs[i].value === fieldVal) {
          allInputs[i].checked = true;
        }
      } else {
        allInputs[i].value = fieldVal;
      }
    }
  }

  let remove_tooltips = function() {
    for (let i = 0; i < tooltips.length; i++) {
      tooltips[i].tip.parentNode.removeChild(tooltips[i].tip);
    }
    tooltips = [];
  };
  let remove_tooltip = function(elem) {
    for (let i = 0; i < tooltips.length; i++) {
      if (tooltips[i].elem === elem) {
        tooltips[i].tip.parentNode.removeChild(tooltips[i].tip);
        tooltips.splice(i, 1);
        return;
      }
    }
  };
  let create_tooltip = function(elem, text) {
    let tooltip = document.createElement('div'), arrow = document.createElement('div'), inner = document.createElement('div'), new_tooltip = {};
    if (elem.type !== 'radio' && elem.type !== 'checkbox') {
      tooltip.className = '_error';
      arrow.className = '_error-arrow';
      inner.className = '_error-inner';
      inner.innerHTML = text;
      tooltip.appendChild(arrow);
      tooltip.appendChild(inner);
      elem.parentNode.appendChild(tooltip);
    } else {
      tooltip.className = '_error-inner _no_arrow';
      tooltip.innerHTML = text;
      elem.parentNode.insertBefore(tooltip, elem);
      new_tooltip.no_arrow = true;
    }
    new_tooltip.tip = tooltip;
    new_tooltip.elem = elem;
    tooltips.push(new_tooltip);
    return new_tooltip;
  };
  let resize_tooltip = function(tooltip) {
    let rect = tooltip.elem.getBoundingClientRect();
    let doc = document.documentElement, scrollPosition = rect.top - ((window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0));
    if (scrollPosition < 40) {
      tooltip.tip.className = tooltip.tip.className.replace(/ ?(_above|_below) ?/g, '') + ' _below';
    } else {
      tooltip.tip.className = tooltip.tip.className.replace(/ ?(_above|_below) ?/g, '') + ' _above';
    }
  };
  let resize_tooltips = function() {
    if (_removed) return;
    for (let i = 0; i < tooltips.length; i++) {
      if (!tooltips[i].no_arrow) resize_tooltip(tooltips[i]);
    }
  };
  let validate_field = function(elem, remove) {
    let tooltip = null, value = elem.value, no_error = true;
    if (remove) {
        remove_tooltip(elem)
    }
    if (elem.type !== 'checkbox') elem.className = elem.className.replace(/ ?_has_error ?/g, '');
    if (elem.getAttribute('required') !== null) {
      if (elem.type === 'radio' || (elem.type === 'checkbox' && /any/.test(elem.className))) {
        let elems = form_to_submit.elements[elem.name];
        if (!(elems instanceof NodeList || elems instanceof HTMLCollection) || elems.length <= 1) {
          no_error = elem.checked;
        }
        else {
          no_error = false;
          for (let i = 0; i < elems.length; i++) {
            if (elems[i].checked) no_error = true;
          }
        }
        if (!no_error) {
          tooltip = create_tooltip(elem, 'Please select an option.');
        }
      } else if (elem.type ==='checkbox') {
        let elems = form_to_submit.elements[elem.name], found = false, err = [];
        no_error = true;
        for (let i = 0; i < elems.length; i++) {
          if (elems[i].getAttribute('required') === null) continue;
          if (!found && elems[i] !== elem) return true;
          found = true;
          elems[i].className = elems[i].className.replace(/ ?_has_error ?/g, '');
          if (!elems[i].checked) {
            no_error = false;
            elems[i].className = elems[i].className + ' _has_error';
            err.push('Checking %s is required'.replace('%s', elems[i].value));
          }
        }
        if (!no_error) {
          tooltip = create_tooltip(elem, err.join('<br/>'));
        }
      } else if (elem.tagName === 'SELECT') {
        let selected = true;
        if (elem.multiple) {
          selected = false;
          for (let i = 0; i < elem.options.length; i++) {
            if (elem.options[i].selected) {
              selected = true;
              break;
            }
          }
        } else {
          for (let i = 0; i < elem.options.length; i++) {
            if (elem.options[i].selected && !elem.options[i].value) {
              selected = false;
            }
          }
        }
        if (!selected) {
          elem.className = elem.className + ' _has_error';
          no_error = false;
          tooltip = create_tooltip(elem, 'Please select an option.');
        }
      } else if (value === undefined || value === null || value === '') {
        elem.className = elem.className + ' _has_error';
        no_error = false;
        tooltip = create_tooltip(elem, 'This field is required.');
      }
    }
    if (no_error && elem.name === 'email') {
      if (!value.match(/^[\\+_a-z0-9-'&=]+(\.[\\+_a-z0-9-']+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i)) {
        elem.className = elem.className + ' _has_error';
        no_error = false;
        tooltip = create_tooltip(elem, 'Enter a valid email address.');
      }
    }
    if (no_error && /date_field/.test(elem.className)) {
      if (!value.match(/^\d\d\d\d-\d\d-\d\d$/)) {
        elem.className = elem.className + ' _has_error';
        no_error = false;
        tooltip = create_tooltip(elem, 'Enter a valid date.');
      }
    }
    if (tooltip) {
        resize_tooltip(tooltip);
    }
    return no_error;
  };
  let needs_validate = function(el) {
        if(el.getAttribute('required') !== null){
            return true
        }
        if(el.name === 'email' && el.value !== ''){
            return true
        }
        return false
  };
  let validate_form = function(e) {
    //TODO this is so bad, need to handle error properly
    let no_error = true;
    if (!submitted) {
      submitted = true;
      for (let i = 0, len = allInputs.length; i < len; i++) {
        let input = allInputs[i];
        if (needs_validate(input)) {
          if (input.type === 'text') {
            addEvent(input, 'blur', function() {
              this.value = this.value.trim();
              validate_field(this, true);
            });
            addEvent(input, 'input', function() {
              validate_field(this, true);
            });
          } else if (input.type === 'radio' || input.type === 'checkbox') {
            (function(el) {
              let radios = form_to_submit.elements[el.name];
              for (let i = 0; i < radios.length; i++) {
                addEvent(radios[i], 'click', function() {
                  validate_field(el, true);
                });
              }
            })(input);
          } else if (input.tagName === 'SELECT') {
            addEvent(input, 'change', function() {
              validate_field(this, true);
            });
          } else if (input.type === 'textarea'){
            addEvent(input, 'input', function() {
              validate_field(this, true);
            });
          }
        }
      }
    }
    remove_tooltips();
    for (let i = 0, len = allInputs.length; i < len; i++) {
      let elem = allInputs[i];
      if (needs_validate(elem)) {
        if (elem.tagName.toLowerCase() !== 'select') {
          elem.value = elem.value.trim();
        }
        no_error = validate_field(elem) ? true : false;
      }
    }
    if (!no_error && e) {
      e.preventDefault();
    }
    resize_tooltips();
    return no_error;
  };
  addEvent(window, 'resize', resize_tooltips);
  addEvent(window, 'scroll', resize_tooltips);
  window._old_serialize = null;
  if (typeof serialize !== 'undefined') window._old_serialize = window.serialize;
  _load_script('//d3rxaij56vjege.cloudfront.net/form-serialize/0.3/serialize.min.js', function() {
    window._form_serialize = window.serialize;
    if (window._old_serialize) window.serialize = window._old_serialize;
  });
  let form_submit = function(e) {
    e.preventDefault();
    if (validate_form()) {
      // use this trick to get the submit button & disable it using plain javascript
      document.querySelector('#_form_5_submit').disabled = true;
      let serialized = window._form_serialize(document.getElementById('_form_5_'));
      let err = form_to_submit.querySelector('._form_error');
      if (err) {
          err.parentNode.removeChild(err)
      }
      _load_script('https://mycelium.activehosted.com/proc.php?' + serialized + '&jsonp=true');
    }
    return false;
  };
  addEvent(form_to_submit, 'submit', form_submit);
})();
}