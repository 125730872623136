import React, { useEffect } from 'react';
import { loadSignup } from './subscribeHelper';

const EmailSubscribeForm = () => {
    const id = `_form_5_`
    useEffect(() => {
        loadSignup();
    }, [])

    return (
        <form method='POST' action='https://mycelium.activehosted.com/proc.php' id={id} className={`_form ${id} _inline-form _inline-style _dark`}>
            <input type='hidden' name='u' value='5' />
            <input type='hidden' name='f' value='5' />
            <input type='hidden' name='s' />
            <input type='hidden' name='c' value='0' />
            <input type='hidden' name='m' value='0' />
            <input type='hidden' name='act' value='sub' />
            <input type='hidden' name='v' value='2' />
            <div className='_form-content m-auto col-12 py-5'>
                <div className='_form-title'>
                    Sign up to our newsletter
                </div>
                <div className='_form_element _x40346555 _inline-style _clear' >
                    <div className='_html-code'>
                        <p>
                            Be the first to hear about new content
                        </p>
                    </div>
                </div>
                <div className='inputs container'>
                    <div className='_form_element _x01555802 _inline-style col-6 p-0' >
                        <label className='_form-label'>
                            First Name*
                        </label>
                        <div className='_field-wrapper pr-2'>
                            <input className='input' type='text' name='firstname' placeholder='Type your first name' required/>
                        </div>
                    </div>
                    <div className='_form_element _x08899801 _inline-style col-6 p-0' >
                        <label className='_form-label'>
                            Last Name*
                        </label>
                        <div className='_field-wrapper'>
                            <input className='input' type='text' name='lastname' placeholder='Type your last name' required/>
                        </div>
                    </div>
                    <div className='_form_element _x81480127 _inline-style col-8 pt-2 p-0'  >
                        <label className='_form-label'>
                            Email*
                        </label>
                        <div className='_field-wrapper'>
                            <input className='input' type='text' name='email' placeholder='Type your email' required/>
                        </div>
                    </div>
                    <div className='_button-wrapper _inline-style col-4 d-flex pr-0'>
                        <button id={`${id}submit`} className='_submit p-1' type='submit'>
                            Submit
                        </button>
                    </div>
                </div>
                <div className='_clear-element'>
                </div>
            </div>
            <div className={`_form-thank-you`} style={{display:'none'}}>
            </div>
            <style>{`
                #${id} { 
                    font-size:14px; 
                    margin:0; 
                    text-align: center;
                    width: 100%;
                    display: flex;
                    margin-top: 2rem;
                    padding-top: 1.5rem;
                    padding-bottom: 3rem;
                }
                ._form_element {
                    padding: 10px;
                }
                #${id} ._form-title { 
                    font-size:30px; 
                    font-weight:600; 
                    margin-bottom:0; 
                }
                ._form-thank-you {
                    width: 100%;
                    font-size: 1.2rem;
                    margin: auto;
                }
                .input {
                    width: 100%;
                    float: left;
                    display: block;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: 0px 0px 5px #0000000D;
                    border: 1px solid #CCCCCC;
                }
                ._submit {
                    width: 100%;
                    color: #fff;
                    margin-top: auto;
                    background-color: #A2763B;
                    box-shadow: 0px 0px 5px #0000000D;
                    border: 1px solid #CCCCCC;
                    transition: 0.2s;
                }
                ._submit:hover {
                    transform: translateY(-2px);
                    cursor: pointer;
                }
                ._button-wrapper {
                    padding-left: 15px;
                    padding-bottom: 10px;
                }
                @media screen and (max-width: 780px) { 
                    ._button-wrapper {
                        padding-left: 10px;
                        padding-top: 20px;
                    }
                    ._form_element {
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                }
                `}
            </style>
        </form>
    )
}

export default EmailSubscribeForm;