import React from "react";
import PropTypes from "prop-types";
import Seo from "./seo";
import NavBar from './navbar';
import Footer from './footer';
import EmailSubscribeForm from './emailSubscribe';
import "../assets/css/simple-grid.min.css";
import "../assets/css/blog.css";
import "../assets/css/main.css";

const Layout = ({ children, seo }) => {
  return (
    <>
      <Seo seo={seo} />
      <main>
        <NavBar />
        {children}
        <div style={{background: 'rgb(224, 224, 224)'}}>
          <div style={{width: '100%'}}>
            <EmailSubscribeForm />
          </div>
        </div>
        <Footer />
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;