import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#000',
    color: '#fff',
    paddingBottom: `${theme.spacing(3)}px`,
    paddingTop: `${theme.spacing(1)}px`,
    flexShrink: 0,
    bottom: 0,
    marginBottom: '-1px', // There was an annoying white space at the bottom
    overflowY: 'hidden', // of the footer please TODO: fix better than this
    fontSize: '0.75em',
  },

  appLogo: {
    height: 30,
    [theme.breakpoints.up('md')]: {
      height: 34
    }
  },

  footerContainer: {
    paddingTop: `${theme.spacing(4)}px`,
    height: '10vh',
  },

  socialsContainer: {
    paddingTop: `${theme.spacing(3)}px`,
    height: '10vh'
  },

  label: {
    display: 'block',
    padding: '0 0 4px',
    color: '#fff'
  },

  link: {
    display: 'block',
    
    padding: '0 0 4px',
    textDecoration: 'underline',
    color: '#fff'
  }
}));


const CustomFooter = () => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Grid container>
        {/* BEGIN: Logo */}
        <Grid item container className={classes.footerContainer} xs={12} md={2} alignItems='center' style={{ textAlign: 'center', flexDirection: 'column' }}>
          <Grid item>
            <a href="https://reputation.link">
              <img
                src="/logo-white.png"
                className={classes.appLogo}
              />
            </a>
          </Grid>
        </Grid>
        {/* END: Logo */}
        {/* BEGIN: Email addresses */}
        <Grid item container className={classes.footerContainer} xs={12} md={4} alignItems='center' style={{ textAlign: 'center', flexDirection: 'column' }}>
          <Grid item xs={12}>
            <span className={classes.label}>Contact:</span>
            <a href='mailto:hello@reputation.link' className={classes.link}>hello@reputation.link</a>
          </Grid>
          <Grid item xs={12}>
            <span className={classes.label}>Support:</span>
            <a href='mailto:support@reputation.link' className={classes.link}>support@reputation.link</a>
          </Grid>
        </Grid>{/* END: Email addresses */}
        <Grid item container className={classes.socialsContainer} xs={6} md={2} alignItems='center' style={{ textAlign: 'center', flexDirection: 'column' }}>{/* BEGIN: Social links */}
          <Grid item xs={12}>
            <a href='https://twitter.com/Oracle_Rep' target='_BLANK' rel='noopener noreferrer' className={classes.link}>Twitter</a>
            <a href='https://medium.com/oracle-reputation' target='_BLANK' rel='noopener noreferrer' className={classes.link}>Medium</a>
            <a href='https://discord.gg/8qBmuud' target='_BLANK' rel='noopener noreferrer' className={classes.link}>Discord</a>
          </Grid>
        </Grid>
        {/* END: Social links */}
        {/* BEGIN: other links */}
        <Grid item container className={classes.footerContainer} xs={6} md={2} alignItems='center' style={{ textAlign: 'center', flexDirection: 'column' }}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            {/* <a href='/' target='_BLANK' className={classes.link}>Terms of Use</a>
                <a href='/' target='_BLANK' className={classes.link}>Privacy Policy</a> */}
            <a href='/developers/api' className={classes.link}>Developers API</a>
            <a href='https://docs.reputation.link' className={classes.link}>Documentation</a>
          </Grid>
        </Grid>
        {/* END: other links */}
        <Grid item container className={classes.footerContainer} xs={12} md={2} alignItems='center' style={{ textAlign: 'center', flexDirection: 'column' }}>
          <Grid item>
            <a href={'https://mycelium.ventures/'} target={'_BLANK'} rel='noopener noreferrer'>
              <img
                src={'/mcv-logo.png'}
                alt='MCV - A fruit of Mycelium Ventures'
                className={classes.appLogo}
              />
            </a>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}

export default CustomFooter;