import React, { useState } from 'react';
import { Hidden, Container, AppBar, Toolbar, Drawer, Box, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { graphql, useStaticQuery } from "gatsby";
import Img from 'gatsby-image';

const Logo = props => {
    const img = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "logo-black.png" }) {
                childImageSharp {
                    fixed(width: 150) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `);
  const classes = useStyles();
  return (
    <a href="https://reputation.link">
       <Img loading="eager" 
            fixed={img.file.childImageSharp.fixed} 
            className={clsx(classes.root, props.className)}
            alt='Vigeo Logo - We Provide A Chainlink Reputation Service'
        />
    </a>
  );
};

const AppLogo = withStyles({
    root: {
        maxWidth: '100%',
        height: 'auto',
        height: '34px'
    }}
  )(Logo);


/**
 * BEGIN: AppBarMenu Component
 */

const useAppBarMenuStyles = makeStyles(theme => ({
    root: {
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%!important'
        },
        alignItems: 'center',
        height: '100%',
        width: '70%'
    },

    menuItem: {
        textTransform: 'initial',
        borderRadius: 0,
        padding: '6px 8px',
        textAlign: 'center',
        fontSize: '0.875rem',
        margin: 'auto',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0',
        }
    },

    menuItemButton: {
        padding: `0 10px`,
        borderRadius: 0,
        borderColor: '#000'
    }
}));

const AppBarMenu = props => {
    const classes = useAppBarMenuStyles();
    const base = 'https://reputation.link'
    return (
        <Box
            display='flex'
            style={props.style}
            className={classes.root}
        >
            <a href={`${base}/oracles`} className={classes.menuItem}>Oracles</a>
            <a href={`${base}/contracts`} className={classes.menuItem}>Contracts</a>
            <a href={`${base}/protocols`} className={classes.menuItem}>Protocols</a>
            <a href={`${base}/chainlink`} className={classes.menuItem}>Network Stats</a>
            <a href={`${base}/apis`} className={classes.menuItem}>Data Curators</a>
            <a href='/' className={classes.menuItem}>Blog</a>
        </Box>
    );
};


/**
 * BEGIN: CustomAppBar Component.
 */

const useStyles = makeStyles(theme => ({
    appBar: {
        backgroundColor: 'white',
        color: 'black',
    },

    appLogo: {
        height: 30,
        [theme.breakpoints.up('md')]: {
            height: 34
        }
    },

    icon: {
        marginLeft: 'auto',
        marginRight: 0
    },

    drawerPaper: {
        [theme.breakpoints.only('sm')]: {
            width: '40%'
        },
        [theme.breakpoints.only('xs')]: {
            width: '70%'
        }
    },
    mobile: {
        alignItems: 'center',
    },
    toolbar: {
        display: 'flex'
    }
}));

const CustomAppBar = props => {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);

    return (
        <AppBar className={classes.appBar} position='static' elevation={2}>
            <Container maxWidth='lg'>
                <Toolbar className={classes.toolbar} disableGutters>
                    <AppLogo className={classes.appLogo} />
                    <Hidden smDown>
                        <AppBarMenu isRegistered={props.isRegistered} currentUser={props.currentUser}
                            registrationComplete={props.registrationComplete} network={props.network} />
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton
                            component='span'
                            edge='end'
                            onClick={() => setDrawerOpen(!drawerOpen)}
                            className={classes.icon}
                        >
                            <MenuIcon style={{ marginLeft: 'auto' }} />
                        </IconButton>
                    </Hidden>
                </Toolbar>
            </Container>

            <Hidden mdUp>
                <Drawer
                    open={drawerOpen}
                    anchor={'right'}
                    onClose={() => setDrawerOpen(false)}
                    classes={{ paper: classes.drawerPaper }}
                >
                    <AppBarMenu isRegistered={props.isRegistered} currentUser={props.currentUser}
                        registrationComplete={props.registrationComplete} />
                </Drawer>
            </Hidden>
        </AppBar>
    );
};


export default CustomAppBar;
